<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newAdjustment'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Adjustment</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose', 'newAdjustment')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12">
                <div class="group">
                  <label class="group-label">Basic Information</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Account Type *</div>
                      <div class="col-md-7 ml-0 pl-0">
                       <select class="form-control"  v-model="formData.accountable_type" required @change="getAccounts">
                              <option value selected disabled>Select Account Type</option>
                              <option
                                v-for="(account, index) in balanceElements.account_types"
                                :key="index"
                                :value="account.id"
                              >{{ account.title }}</option>
                      </select>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Account Name *</div>
                      <div class="col-md-7 ml-0 pl-0">
                       <select class="form-control" v-model="formData.accountable_id" required>
                              <option value selected disabled>Select Account Name</option>
                              <option
                                v-for="(account, index) in accounts"
                                :key="index"
                                :value="account.id"
                              >{{ account.account_name }}</option>
                      </select>
                      </div>
                    </div>
                     <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Type *</div>
                      <div class="col-md-7 ml-0 pl-0">
                       <select class="form-control" v-model="formData.type" required>
                              <option value="1">DR</option>
                              <option value="2">CR</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-5 mr-0 pr-0">Amount *</div>
                      <div class="col-md-7 input-group ml-0 pl-0">
                        <input
                              type="number"
                              step="any"
                              min="1"
                              v-model="formData.amount"
                              class="form-control"
                              id="tax_amount"
                              placeholder="0.00"
                              required
                            />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5 mr-0 pr-0">Description</div>
                      <div class="col-md-7 input-group ml-0 pl-0">
                        <textarea
                              class="form-control"
                              id="loan_description"
                              type="text"
                              v-model="formData.description"
                            ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{ disableSubmitButton ? 'Submitting....' : 'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("adjustments", ["balanceDatas", "balanceElements"]),
  },
  data() {
    return {
      formData: {
        type: 1,
        accountable_id:'',
        accountable_type: "",
        amount: null,
        description: null,
      },
      disableSubmitButton: false,
      accounts:[],
    };
  },   
  methods: {
    submit() {
      this.disableSubmitButton = true;
      Services.createNewAdjustment(this.formData)
        .then((res) => {
          this.$emit("parent-event");
          this.$store.dispatch("modalClose");
          this.setNotification(res.data.success_message);
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    getAccounts(){
        let details = [];
        let account_type = this.formData.accountable_type;
        this.accounts = [];
        this.formData.accountable_id = "";
        if (account_type == this.balanceElements.supplier_account_id) {
          details = this.balanceElements.suppliers;
        } else if (account_type == this.balanceElements.customer_account_id) {
          details = this.balanceElements.customers;
        }
        this.accounts = { ...details };
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    clearData() {
      this.formData.accountable_id="";
      this.formData.type = 1;
      this.formData.accountable_type = "";
      this.formData.amount = null;
      this.formData.description = null;
      this.disableSubmitButton = false;
    },
  },
  watch: {
    modalId(value) {
      if (value == "newAdjustment") {
        this.clearData();
        Services.getBalanceElements()
          .then((res) => {
            this.$store.commit("adjustments/setBalanceElements", res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
