<template>
  <div class="content-body">
    <div class="row mt-2 mt-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Balance Adjustment
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Manage Balance Adjustment
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_pay_in')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newAdjustment',
              mode: 'create',
            })
          "
        >
          New Balance Adjustment
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0">
          <select
            v-model="params.size"
            @change="filterDatas"
            class="mr-2 mg-t-5"
            id="pay_in_filter_size"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Account Type</th>
              <th class="wd-10p">Account Name</th>
              <th class="wd-10p">Type</th>
              <th class="wd-10p">Amount.</th>
              <th class="wd-20p">Created By</th>
              <th
                class="wd-10p text-center table-end-item no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_balance_adjustment',
                    'edit_balance_adjustment',
                    'cancel_balance_adjustment',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && balanceDatas.length > 0">
            <tr v-for="(balance, index) in balanceDatas" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}.
              </th>
              <td><span v-if="balance.accountable_type == 'App\\Modules\\Customer\\Entities\\Customer'">Customer</span><span v-else>Supplier</span></td>
              <td>{{ balance.accountable.name }}</td>
              <td><span v-if="balance.type == 1">DR</span><span v-else>CR</span></td>
              <td>{{ parseDigitForSlip(balance.amount) }} <span class="tx-danger" v-if="balance.status == 1">-Canceled</span></td>
              <td>{{ balance.user.name }}</td>
              <td
                class="text-right table-end-item no-print"
                v-if="
                  checkIfAnyPermission([
                    'view_balance_adjustment',
                    'edit_balance_adjustment',
                    'cancel_balance_adjustment',
                  ])
                "
              >
                <!-- <a
                  href="javascript:;"
                  class="mr-3"
                  title="View Slip"
                  v-if="checkSinglePermission('view_balance_adjustment')"
                >
                  <i class="fas fa-eye" @click="view('taxSlip', balance.id)"></i>
                </a> -->
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="Edit Balance Adjustment"
                  v-if="
                     balance.status == 0 && checkSinglePermission('edit_balance_adjustment')
                  "
                >
                  <i
                    class="fas fa-edit"
                    @click="view('editAdjustment', balance.id)"
                  ></i> 
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  v-if="
                   balance.status == 0 && checkSinglePermission('cancel_balance_adjustment')
                  "
                  title="Cancel Balance Adjustment"
                >
                  <i
                    class="fas fa-times-circle tx-danger"
                    @click="cancel(balance.id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ error_message }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && balanceDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No Records Found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <Create @parent-event="getData" />
    <Edit @parent-event="getData" /> 
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Create from "./create";
import Paginate from "vuejs-paginate";
import Edit from "./edit";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  //mixins: [Search],
  data() {
    return {
      params: {
        size: 10,
        offset: 0,
      },
        loading: true,
      page: 1,
      error: false,
      error_message: "",
      //pagination required
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
    };
  },
  components: {
    Paginate,Create,Edit
  },
  computed: {
    ...mapGetters([
      "icons",
      "authData",
      "dataId",
      "dataLists",
      "dataLists2",
      "today",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("adjustments", ["balanceDatas", "balanceElements"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    //pagination method
    clickCallback: function (page) {
      console.log(page);
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    filterDatas: _.debounce(function () {
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      this.disableSubmitButton = true;
      Services.getBalanceDatas(this.params)
        .then((res) => {
          this.$store.commit("adjustments/setBalanceDatas", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error = true;
          this.loading = false;
          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    }, 1000),
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Balance Adjustment ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return Services.cancelAdjustment(id)
            .then((response) => {
              this.getData();
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    setNotification(msg) {
      console.log(msg);
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getData() {
      this.loading = true;
      Services.getBalanceDatas(this.params)
        .then((res) => {
          this.$store.commit("adjustments/setBalanceDatas", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error = true;
          this.loading = false;
        });
    },
    getPayInElements() {
      Services.getTaxPayrollElements()
        .then((res) => {
          this.$store.commit("taxPayrolls/setTaxElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>